export const IMAGE_EXTENSIONS = '.png, .jpg, .jpeg, .heic, .HEIC';
export const WEBCAM_IMAGE_FORMAT = 'image/jpeg';

export const SEND_GIFTOON_MODALS = {
  SENDING_TIME: 'sendingTime',
  DATE_PICKER: 'datePicker',
};

export const GIFT_VIEW_MODALS = {
  SHARE_MODAL: 'shareModal',
  EMAIL_MODAL: 'emailModal'
};

export const PROFILE_TABS = {
  SETTINGS: 'settings',
  SENT_GIFTOONS: 'sent_giftoon',
  WALLET: 'wallet',
};

export const VIDEO_STATES = {
  NOT_PLAYED: 'notPlayed',
  PLAYING: 'playing',
  PAUSED: 'paused',
  FINISHED: 'finished',
  REPEATING: 'repeating',
};

export const SELECT_PHOTO_MODALS = {
  WEBCAM: 'webcam',
  WITHOUT_AUTH: 'withoutAuth',
  GET_FACEBOOK_PERMISSION: 'getFacebookPermission',
  IMAGE_GALLERY: 'imageGallery',
};

export const GIFTOON_TO_WHOM = {
  GIFT: 'gift',
  MYSELF: 'mySelf',
};

export const FLOW_TYPES = {
  GIVEAWAY: 'GIVEAWAY',
  BRAND: 'BRAND',
};

export const SHOP_BRANDS_LIMIT = 9;
export const SHOP_GIVEAWAY_LIMIT = 12;
export const RESENT_EMAIL_TIMEOUT = 30000;
export const HOME_BANNER_SLIDER_SPEED = 5000;

export const HOME_BRANDS_LIMIT = 12;
export const HOME_GIVEAWAY_CATEGORIES_LIMIT = 6;

export const SENT_GIFTOONS_LIMIT = 6;
export const PROFILE_WALLET_LIMIT = 6;

export const PHONE_PREFIX = '+61';

export const TAX_PERCENT = 6.19;

export const HELP_EMAIL = 'hello@giftoon.com';

export const INSTAGRAM_LINK = 'https://www.instagram.com/_giftoon/?hl=en';
export const FACEBOOK_LINK = 'https://facebook.com/Giftoonofficial';
export const TIKTOK_LINK = 'https://www.tiktok.com/@giftoon.com';

export const APP_DOWNLOAD_LINK = 'https://apps.apple.com/us/app/giftoon/id6443819902';

export const BRAND_DESCRIPTION_MAX_HEIGHT = 81;
