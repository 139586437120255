import React, {
  useCallback, useEffect, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PURCHASE_COMPLETED, STEP_SEND_GIFTOON } from '../../constants/links';
import getTaxPrice from '../../helpers/getTaxPrice';
import getFullPrice from '../../helpers/getFullPrice';
import { CheckoutGetUserThunk } from '../../store/checkout/slice';
import notify from '../../utils/notify';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import PayPalButton from '../../components/PayPalButton/PayPalButton';
import SendingPage from '../../layout/SendingPage/SendingPage';
import PriceBlock from './components/PriceBlock';
import GiftoonInfo from './components/GiftoonInfo';
import styles from './sass/Checkout.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';

const Checkout = () => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    giftReceiver: {
      email,
      sendAt,
      firstName,
      lastName,
      message,
      phone,
      senderName,
      toWhom,
    },
    giftFlow: {
      savedData: {
        price: giftoonPrice,
        brandName,
        giveawayName,
        giveawayId,
        brandId,
        imageId,
        faceId,
        buyJustCoupon,
        tcnActivationFee,
      }, data: { id: giftoonId }
    },
    profile: { data: { id: userId } },
    auth: { tokens: { accessToken } },
  } = useSelector((state) => ({
    giftReceiver: state.giftReceiver,
    giftFlow: state.giftFlow,
    profile: state.profile,
    checkout: state.checkout,
    auth: state.auth,
  }));
  const taxPrice = useMemo(() => (`${getTaxPrice(giftoonPrice)}`), [giftoonPrice]);
  const totalPrice = useMemo(() => {
    return getFullPrice(giftoonPrice, tcnActivationFee);
  }, [giftoonPrice, taxPrice, tcnActivationFee]);

  const handleCreateOrder = useCallback(() => {}, []);
  const handleCancel = useCallback(() => notify('error', 'Payment has been canceled'), []);
  const handleError = useCallback(() => notify('error', 'Something went wrong'), []);

  const handleApprove = useCallback((data) => {
    navigate(`${PURCHASE_COMPLETED}/${data.orderID}`);
    window.gtag('event', 'purchase', {
      transaction_id: data.orderID,
      affiliation: 'Giftoon',
      value: +totalPrice,
      tax: +taxPrice,
      currency: 'AUD',
      items: [
        {
          item_name: `${giveawayName} + ${brandName}`,
          item_brand: brandName,
          item_category: giveawayName,
          currency: 'AUD',
          price: +giftoonPrice,
          quantity: 1
        }]
    });
  }, []);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.button}
      link={STEP_SEND_GIFTOON}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
    />
  ), []);

  const checkoutShouldBeRendered = useMemo(() => (
    (buyJustCoupon && brandId) || (giveawayId && brandId && imageId && faceId)
  ), [giveawayId, brandId, imageId, faceId]);

  useEffect(() => {
    const rtscript = document.createElement('script');
    rtscript.type = 'text/javascript';
    rtscript.src = 'https://cdn.pinpayments.com/pin.v2.js';
    document.body.appendChild(rtscript);
    return () => document.body.removeChild(rtscript);
  }, []);

  useEffect(() => {
    if (accessToken) {
      dispatch(CheckoutGetUserThunk({ id: userId }));
    }
  }, []);

  return (
    <SendingPage stepName={t('payGiftoon')} buttonLeft={buttonLeft}>
      {checkoutShouldBeRendered && (
      <section className={styles.section}>
        <div className={styles.infoContainer}>
          <h2 className={styles.infoHeading}>{buyJustCoupon ? t('eGiftCard') : t('heading')}</h2>
          <GiftoonInfo
            giftoonName={giveawayName}
            couponName={brandName}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            email={email}
            message={message}
            couponPrice={`${giftoonPrice}`}
            date={JSON.parse(sendAt) ? new Date(JSON.parse(sendAt)) : null}
            senderName={senderName}
            toWhom={toWhom}
          />
        </div>
        <div className={styles.checkoutContainer}>
          <h2 className={styles.checkoutHeading}>{t('checkout')}</h2>
          <PriceBlock
            giftoonPrice={`${giftoonPrice}`}
            taxPrice={taxPrice}
            tcnActivationFee={tcnActivationFee}
            totalPrice={totalPrice}
          />
          <div className={styles.payButtonsContainer}>
            <PayPalButton
              onApprove={handleApprove}
              onCreateOrder={handleCreateOrder}
              onCancel={handleCancel}
              onError={handleError}
              price={totalPrice}
              giftoonId={giftoonId}
            />
          </div>
        </div>
      </section>
      )}
    </SendingPage>
  );
};

export default Checkout;
