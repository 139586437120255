import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PINPAY_CHARGE, USERS_API } from '../../constants/api';
import instance from '../../services/axios';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  email: '',
  data: {},
};

export const CheckoutGetUserThunk = createAsyncThunk(
  'checkout/CheckoutGetUserThunk',
  async (
    { id },
    { rejectWithValue }
  ) => {
    try {
      const result = await instance.get(`${USERS_API}${id}/`);
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const CheckoutPinPayChargeThunk = createAsyncThunk(
  'checkout/CheckoutPinPayChargeThunk',
  async (
    { userEmail, giftoonId, cardToken },
    { rejectWithValue }
  ) => {
    try {
      const result = await instance.post(PINPAY_CHARGE, {
        email: userEmail,
        giftoon_id: giftoonId,
        card_token: cardToken
      });
      window.location.href = result.data.response.redirect_url;
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', data.error_description || Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const CheckoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CheckoutPinPayChargeThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(CheckoutPinPayChargeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(CheckoutPinPayChargeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(CheckoutGetUserThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(CheckoutGetUserThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.email = action.payload.email;
      })
      .addCase(CheckoutGetUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default CheckoutSlice.reducer;
