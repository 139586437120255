export default {
  button: {
    goECards: 'Go eCards'
  },

  header: {
    announcementBar: 'Australia’s newest & most innovative eGift Cards, where the recipient is the star of the show!',
    seeAllBrands: 'See all Brands',
    brands: 'Brands',
    giftoon: 'Giftoon',
    buyGiftCards: 'Buy Gift Cards',
    wallet: 'Wallet',
    about: 'About',
    giftoons: 'Giftoons',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    logout: 'Logout',
    myAccount: 'My Account',
    digitalDelivery: 'digital delivery',
  },

  footer: {
    shop: 'Shop',
    aboutUs: 'About Us',
    finePrint: 'Fine Print',
    support: 'Support',

    buyGiftCards: 'Buy Gift Cards',

    howItWorks: 'How it works',
    ourWhy: 'Our Why',
    corporate: 'Corporate',

    privacyPolicy: 'Privacy Policy',
    termsConditions: 'Terms & Conditions',

    contactUs: 'Contact Us',
    footerInfo: 'We acknowledge Aboriginal and Torres Strait Islander peoples as the traditional custodians of this'
      + ' land - Australia.\nThe Gadigal of the Eora Nation are the traditional custodians of the place'
      + ' we now call Sydney, in which we are fortunate to work and reside in.',
    companyName: '(c) 2023 App Happy Pty Ltd',
  },

  signIn: {
    signIn: 'Sign In',
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot password?',
    keepSignedIn: 'Keep me signed in',
    or: 'OR',
    continueAsAGuest: 'Continue as a guest',
    facebookSignIn: 'Sign In with Facebook',
    dontHaveAccount: 'Don\'t have an account?',
    createAccount: 'Create Account',
    resendEmail: 'Resend email',
    ifAlreadyRegistered: 'If you already registered, but you haven’t verified your account yet. ',
    verifyHere: 'Verify here',
  },

  signUp: {
    signUp: 'Sign Up',
    signIn: 'Sign In',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat password',
    keepSignedIn: 'Keep me signed in',
    or: 'OR',
    facebookSignIn: 'Sign In with Facebook',
    alreadyHaveAccount: 'I already have an account'
  },

  resetPassword: {
    resetPassword: 'Reset Password',
    enterEmail: 'Enter the email address you use on Giftoon to reset your password.',
    email: 'Email',
    reset: 'Reset',
    note: 'Note',
    checkSpam: 'If you aren’t receiving your password reset  email, be sure to check your email account’s spam folder'
  },

  forgotPassword: {
    resetPassword: 'Forgot Password',
    enterNewPassword: 'Enter new password',
    password: 'Password',
    repeatPassword: 'Repeat password',
    reset: 'Reset',
  },

  resetEmailSent: {
    emailWasSent: 'The email was sent',
    followInstructions: 'Please follow the instructions we just sent to your email',
    next: 'Next'
  },

  verifyEmail: {
    emailWasSent: 'The email was sent',
    followInstructions: 'An email has been sent to you with a link to confirm your email.',
    next: 'Next',
    resendEmail: 'Resend Email',
  },

  shopBrands: {
    giftoon: 'Giftoon',
    shopBrands: 'Shop Brands',
    shopBrandsDescription: 'All the best brands in one convenient place. Choose the retailer, specify the $, '
        + 'then through to the next step to select the Giftoon video greeting card.',
    searchBrands: 'Search Brands',
  },

  shopGiftoons: {
    giftoon: 'Giftoon',
    chooseGiftoon: 'Choose a Giftoon',
    description: 'Remember to select the video greeting card that best suits the occasion being celebrated, their'
      + ' personality or interests. For instance, proud of someone? Salute them with “A Toast, to You”. Celebrating a'
      + ' gamer’s birthday? Bundle their eGift card with the 80’s inspired “Gamers don’t age” video. So the recipient'
      + ' loves to dance, do they? Check out “You’re a star” or “Wild Child”. Best thing - once the video is selected,'
      + ' we upload their face onto the character with the “?” face, and don\'t worry, we do the heavy lifting for you.'
      + ' Let\'s begin!',
    goECards: 'Go eCards',
    person: 'Person',
    persons: 'Persons'
  },

  home: {
    giftoon: 'Giftoon',
    homeBannerHeading: 'Thoughtful eGift Cards\nthat suit ',
    homeBannerHeading2: 'everyone',
    homeBannerDescription: 'Find, personalise and send\nin less than 2 minutes.',
    shopNow: 'Shop Now',
    browseGiftCards: 'Browse Gift Cards',
    personaliseIt: 'Personalise it',
    giveYourLovedOne: 'Give your loved one a laugh by adding an optional personalised Giftoon video to your gift card!',
    simplyAddTheirFace: 'Simply add their face in to any video for any occasion, and give them the gift of giggle. ',
    howItWorks: 'How It Works',

    howItWorksStepOneHeading: 'Select Gift Card',
    howItWorksStepTwoHeading: 'Select Video',
    howItWorksStepThreeHeading: 'Personalize It',
    howItWorksStepFourHeading: 'We send it!',

    howItWorksStepOneDescription: 'Choose an eGift card from Australia`s favourite brands.',
    howItWorksStepTwoDescription: 'Select a video to suit the occasion (e.g. Birthday, Congratulations. Love)',
    howItWorksStepThreeDescription: 'Upload the recipients face and make them the star of the video!',
    howItWorksStepFourDescription: 'We bundle your gift card and video, send it immediately or at your chosen time.',

    shippingForSpecialPeople: 'Shopping for the special people in your life just got easier!',
    withEGiftCards: 'With eGift cards from all of your favourite brands, including Woolworth, Amazon. Rebel and '
      + 'Doordash - there`s something to suit everyone. ',
    spoilLovedOnes: 'Spoil loved ones with a message and beauty products, or treat them to a night '
      + 'out eating at a range of amazing restaurants. There`s even travel gift cards for the post covid '
      + 'global wanderers, cinema cards for the movie buffs and so much more. ',
    checkItOut: 'Check it out',

    searchByCategory: 'Search stores by category',
    asFeatured: 'As featured on',
  },

  sendingPage: {
    exit: 'Exit',
    step: 'Step',
    clock: 'Clock',

    back: 'Back',
    nextStep: 'Next Step',
    selectBrand: 'Select Brand',

    addCoupon: 'Add Coupon',

    sendGiftoon: 'Send Giftoon',
  },

  selectGiftoon: {
    selectGiftoon: 'Select Giftoon',
    selectBrand: 'Select Brand',
    back: 'Back',
    nextStep: 'Next Step',
    person: 'Person',
    persons: 'Persons',
    sec: 'sec',
  },

  selectBrands: {
    selectGiftoon: 'Select Giftoon',
    selectBrands: 'Select Brands',
    selectAmount: 'Select Amount',
    addCoupon: 'Add Coupon',
    noExpirationDate: 'No expiration date',
    selectCardValue: 'Select card value (AUD)',
    enterAmount: 'Enter amount from',
    to: 'to',
    back: 'Back',
    nextStep: 'Next Step: Add Video',
    sendCoupon: 'Checkout Now: Gift Card Only',
    amountErrorMessage: 'Please, enter valid price',
    retailerGiftCard: 'Retailer Gift Card Terms & Conditions',
    readMore: 'Read more',
    readLess: 'Read less',
    retailerTerms: 'Retailer Gift Card Terms & Conditions',
  },

  addBrandList: {
    back: 'Back',
    addBrand: 'Add Brand',
    searchBrands: 'Search Brands',
  },

  addGiftoonList: {
    back: 'Back',
    addGiftoons: 'Add Giftoons',
    selectGiftoon: 'Select Giftoon',
    description: 'Select the video greeting card that best suits the occasion, their personality or interests.'
        + ' Click through to preview.',
  },

  sendGiftoon: {
    back: 'Back',
    sendLater: 'Send Later',
    sendNow: 'Send Now',

    sendGiftoon: 'Send Giftoon',

    recipientsDetails: 'Recipient\'s Details',
    buyAsGift: 'Buy as a gift',
    buyForMyself: 'Buy for myself',

    name: 'Recipient\'s Name',
    surname: 'Recipient\'s Surname',
    yourName: 'Your Name',
    yourSurname: 'Your Surname',
    email: 'Recipient\'s Email',
    yourEmail: 'Your Email',
    message: 'Message',

    sendersDetails: 'Sender\'s details',
    sendersName: 'Sender\'s Name',
    sendersEmail: 'Sender\'s Email',

    sendingTime: 'Sending Time',
    sandAtScheduledTime: 'Send at scheduled time',

    giftoonWillBeSent: 'Your Giftoon will be sent at the appointed time, please check your mail',
    ok: 'Ok',

    cancel: 'Cancel',
    apply: 'Apply',
  },

  selectPhoto: {
    back: 'Back',
    nextStep: 'Next Step',
    addFace: 'Add a Face',
    startWithPhoto: 'Start with a face photo!',
    description: 'Tip: For the best results, upload a clear, clean photo in jpg or png format with the recipient\'s '
        + 'face in good lighting.',
    facebook: 'Facebook',
    webcam: 'Webcam',
    browsePhotos: 'Browse Photos',

    beforeYouContinue: 'Before you continue',
    pleaseSignIn: 'Please sign in or sign up for Giftoon to get started.',
    signUp: 'Sign Up',
    signIn: 'Sign In',
    continueAsGuest: 'Continue as a guest',

    oneMoment: 'One moment',
    accessYourPhotos: 'We were unable to access your facebook photos',
    grantAccess: 'Grant access',
    tryAnotherWay: 'Try another way',
  },

  cropPhoto: {
    back: 'Back',
    saveChanges: 'Save Changes',
    addFace: 'Add a Face',
    createCharacter: 'Create a New Character',
    description: 'Use the handles to adjust the face size and rotation. '
      + 'Make sure the ears are outside of the silhouette.',
    scale: 'Scale',
    rotate: 'Rotate',
  },

  checkPhoto: {
    back: 'Back',
    nextStep: 'Next Step',
    addFace: 'Add a Face',
    editFace: 'Edit Face',
    lookinGood: 'Lookin’ Good.',
    selectTheRightFace: ' Select the right face to use',
  },

  wallet: {
    wallet: 'Wallet',
    new: 'NEW',
    useCoupon: 'Use coupon',
    couponInfo: 'Coupon info',
    couponUsed: 'CouponList already used',
    noCoupons: 'You have not received \n Giftoons yet 🎁',
    noExpirationDate: 'No expiration date',
    digitalDelivery: 'digital delivery',

    cardNumber: 'Card number',
    pin: 'Pin',
  },

  profile: {
    myProfile: 'My Profile',
    settings: 'Profile Settings',
    sent_giftoon: 'Sent Giftoons',
    wallet: 'Wallet',
  },

  profileSettings: {
    myInfo: 'My Info',
    yourName: 'Your name',
    yourEmail: 'Your email',
    saveProfile: 'Save Profile',
    cardNumber: 'Card number',
    edit: 'Edit',
    mastercard: 'mastercard',
    saveCard: 'Save Card',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    minCharactersLong: 'At least 8 characters long',
    savePassword: 'Save Password',
    deleteAccount: 'Delete Account',
    areYouSure: 'Are you sure?',
    thisWillEraseAccount: 'This will permanently erase your account!',
    delete: 'Delete',
    cancel: 'Cancel',
  },

  profileSentGiftoons: {
    eCards: 'eCards',
    coupons: 'Coupons',
    recipient: 'Recipient',
    date: 'Date',
    withoutGiftoon: 'Without giftoon',
  },

  giftView: {
    facebookShare: 'Share on Facebook',
    copyLink: 'Copy Link',
    giftoonShareText: 'Giftoon',
    sendByEmail: 'Send by Email',
    theirName: 'Their name',
    sendEmail: 'Send Email',
    to: 'To',
    send: 'Send!',
    theirEmail: 'Their email',
    shareVideo: 'Share video',
    enjoy: 'Enjoy',
    starring: 'starring',
    plus: 'plus',
    claimEGift: 'Claim eGift Card',
    youReceived: 'you\'ve received',
    of: 'of',
    creditFrom: 'credit from',
  },

  checkout: {
    back: 'Back',
    heading: 'eGift Card + Giftoon bundle',
    giftoon: 'Giftoon',
    eGiftCard: 'eGift Card',
    to: 'To',
    phone: 'Phone',
    email: 'Email',
    recipient: 'Recipient',
    sending: 'Sending',
    now: 'Now',
    from: 'From',
    message: 'Message',
    payGiftoon: 'Pay Giftoon',

    checkout: 'Checkout',
    cardNumber: 'Card Number',
    cvvNumber: 'CVV Number',
    expireDate: 'Expire Date',
    name: 'Name on Card',
    address: 'Address Line',
    city: 'City',
    country: 'Country',
    yourEmail: 'Your email for the report',
    noFee: 'no fee',
    processingFee: 'Processing fee',
    tcnActivationFee: 'TCN Card activation fee',
    youHaveToPay: 'You have to pay',
    payNow: 'Pay Now',
    applePay: 'Apple Pay',
    payPal: 'PayPal',
  },

  purchaseCompleted: {
    purchaseCompleted: 'Purchase completed',
    giftoonSent: 'Giftoon Sent! 🎉🕺',
    success: 'Success! 🎉🕺',
    sendAt: 'The Giftoon will be sent',
    emailConfirming: 'An email confirming your',
    purchaseHasBeenSent: 'purchase has been sent to',
    orderNumber: 'Order Number',
    toHomepage: 'To home page',
  },

  contactUs: {
    contact: 'Contact',
    getInTouch: 'Get in touch via the form below and we’ll reply as soon as we can.',
    name: 'Name',
    email: 'Email',
    yourMessage: 'Your message',
    sendMessage: 'Send Message',
    yourMessageWasSent: 'Thanks for contacting us!',
    weWillGetInTouch1: 'We appreciate your patience.',
    weWillGetInTouch2: 'We will be in touch as soon as possible',
    weWillGetInTouch3: '- usually within 24hrs.',
    ok: 'Ok',
  },

  howItWorks: {
    howItWorks: 'How it works',

    stepOneHeading: 'Choose the eGift Card',
    stepOneDescription: 'Choose the retailer your loved one will enjoy shopping with, specify how much you want to gift'
        + ' them to spend at this retailer, and proceed to the next step.',

    stepTwoHeading: 'Select & personalise the Giftoon',
    stepTwoDescription1: 'Select and preview a video greeting card that suits the occasion being celebrated, '
        + 'their personality or interests! Personalise it by uploading a clear picture of your loved one via the social'
        + ' media or device options.',
    stepTwoDescription2: 'Our facial recognition technology pulls the face from your picture, transferring it'
        + ' into the video automatically. If there are 2+ faces then you will be asked to choose which one to use.'
        + ' At this stage, you can now preview before proceeding.',
    stepTwoDescription3: 'Tip: For the best results, upload clear, clean photos that show the recipient\'s face in good'
        + ' lighting only.',

    stepThreeHeading: 'Finalise and send',
    stepThreeDescription: 'Specify to whom, where and when it’s being sent. You can send now or schedule for later.'
        + ' Make sure you fill details correctly so we can send you your confirmation. Once you pay,'
        + ' we bundle everything together and send it on your behalf at the specified time. Easy!',

    stepFourHeading: 'Your loved one receives their Giftoon',
    stepFourDescription1: 'They receive an sms or email link which takes them to the eGift card, via the personalised'
        + ' Giftoon video greeting card.\n'
        + 'This is where the magic happens! Have you ever seen yourself in an unusual or unexpected position before?'
        + ' Surprise - you have their attention! Dopamine is released, smiles inspired and a meaningful moment created'
        + ' by yours truly (you!).',
    stepFourDescription2: 'Once the video has been viewed, they can claim the eGift Card,'
        + ' save the video and/or share it to social media for some more laughs, or to thank you.'
        + ' If they have a Giftoon account they can store the eGift Card inside, and present it'
        + ' to the retailer at the time they wish to claim.',
    stepFourDescription3: 'If you have any questions, shoot us an email at ',
    stepFourDescription4: 'hello@giftoon.com',
    stepFourDescription5: ' and we’ll be happy to help',
    selectAGiftoon: 'Select a Giftoon',
  },

  corporate: {
    giftoonForBusiness: 'Giftoon for Business',
    description1: 'Giftoon business is coming soon.',
    description2: 'Please leave your details and we will inform you of the product pre launch.',
  },

  receivedGiftoon: {
    receivedGiftoon: 'Received Giftoon 🎉💰',
    sayThankYou: '🙏 Say Thank You ',
  },

  error: {
    contentDoesntExist: 'Sorry, the content you’re looking for doesn’t exist.'
      + ' Either it was removed, or you mistyped the link.',
    goToHomepage: 'Go to homepage',
  }
};
