import React from 'react';
import {
  PayPalScriptProvider,
  PayPalButtons,
} from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import styles from './sass/PayPalButton.module.scss';

const PayPalButton = ({
  price, giftoonId, onCancel, onApprove, onCreateOrder, onError,
}) => {
  return (
    <PayPalScriptProvider
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: 'AUD',
        locale: 'en_AU'
      }}
    >
      <PayPalButtons
        className={styles.paypal}
        style={{ height: 50 }}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  custom_id: giftoonId,
                  amount: {
                    currency_code: 'AUD',
                    value: price,
                  },
                },
              ],
              application_context: {
                shipping_preference: 'NO_SHIPPING'
              }
            })
            .then((orderId) => {
              onCreateOrder(data, orderId);
              return orderId;
            });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(() => {
            onApprove(data);
          });
        }}
        onCancel={(data) => onCancel(data)}
        onError={(err) => onError(err)}
      />
    </PayPalScriptProvider>
  );
};

PayPalButton.propTypes = {
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onCreateOrder: PropTypes.func,
  price: PropTypes.string.isRequired,
  giftoonId: PropTypes.number.isRequired,
};

PayPalButton.defaultProps = {
  onApprove: () => {},
  onCancel: () => {},
  onError: () => {},
  onCreateOrder: () => {},
};

export default PayPalButton;
