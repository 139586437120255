import React from 'react';
import PropTypes from 'prop-types';
import numStringToDouble from '../../../helpers/numStringToDouble';
import styles from '../sass/PriceLine.module.scss';

const PriceLine = ({ price, text, isTotalPrice }) => {
  return (
    <div className={`${styles.priceLine} ${isTotalPrice ? styles.totalPriceLine : ''}`}>
      <p className={styles.payText}>{text}</p>
      <p className={styles.price}>{`$${numStringToDouble(price)}`}</p>
    </div>
  );
};

PriceLine.propTypes = {
  price: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isTotalPrice: PropTypes.bool,
};

PriceLine.defaultProps = {
  isTotalPrice: false,
};

export default PriceLine;
