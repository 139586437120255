import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { GIFTOON_TO_WHOM } from '../../../constants/constants';
import GiftoonInfoLine from './GiftoonInfoLine';
import styles from '../sass/GiftoonInfo.module.scss';

const GiftoonInfo = ({
  giftoonName,
  couponName,
  firstName,
  lastName,
  phone,
  email,
  message,
  couponPrice,
  date,
  senderName,
  toWhom
}) => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });

  const sendAt = useMemo(() => {
    if (date) {
      const day = DateTime.fromJSDate(date).toFormat('dd MMMM, yyyy');
      const time = DateTime.fromJSDate(date).toFormat('T a');
      return `${day} at ${time}`;
    }
    return t('now');
  }, [date]);

  const showSenderName = useMemo(() => (
    toWhom === GIFTOON_TO_WHOM.GIFT && senderName
  ), [toWhom, senderName]);

  return (
    <div className={styles.giftoonInfo}>
      {giftoonName && <GiftoonInfoLine heading={t('giftoon')} value={giftoonName} />}
      <GiftoonInfoLine heading={t('eGiftCard')} value={`${couponName} ($${couponPrice})`} />
      <GiftoonInfoLine heading={t('to')} value={`${firstName} ${lastName}`} />
      <GiftoonInfoLine heading={t(email ? 'email' : 'phone')} value={email || phone} />
      <GiftoonInfoLine heading={t('sending')} value={sendAt} />
      {message && (<GiftoonInfoLine heading={t('message')} value={message} />)}
      {showSenderName && (<GiftoonInfoLine heading={t('from')} value={senderName} />)}
    </div>
  );
};

GiftoonInfo.propTypes = {
  giftoonName: PropTypes.string,
  couponName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  senderName: PropTypes.string.isRequired,
  toWhom: PropTypes.string.isRequired,
  couponPrice: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
};

GiftoonInfo.defaultProps = {
  giftoonName: '',
  date: null,
};

export default GiftoonInfo;
