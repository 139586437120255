import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PriceLine from './PriceLine';
import styles from '../sass/PriceBlock.module.scss';

const PriceBlock = ({
  giftoonPrice, taxPrice, tcnActivationFee, totalPrice
}) => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });

  return (
    <div className={styles.priceBlock}>
      <PriceLine price={giftoonPrice} text={`${t('eGiftCard')} (${t('noFee')})`} />
      <PriceLine price={taxPrice} text={t('processingFee')} />
      {tcnActivationFee && <PriceLine price={`${tcnActivationFee}`} text={t('tcnActivationFee')} />}
      <hr />
      <PriceLine price={totalPrice} text={t('youHaveToPay')} isTotalPrice />
    </div>
  );
};

PriceBlock.propTypes = {
  giftoonPrice: PropTypes.string.isRequired,
  taxPrice: PropTypes.string.isRequired,
  tcnActivationFee: PropTypes.number,
  totalPrice: PropTypes.string.isRequired,
};

PriceBlock.defaultProps = {
  tcnActivationFee: null,
};

export default PriceBlock;
